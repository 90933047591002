<template>
  <div
    v-if="isBannerPage && closeBanner"
    id="navbarBanner"
    class="bg-[#0abc28] w-full m-auto max-w-[1320px] flex justify-items-center p-1 md:p-2 gap-1 md:gap-4"
  >
    <div
      class="flex flex-col md:flex-row grow gap-1 md:gap-4 justify-items-center"
    >
      <div class="grow md:pb-2 m-auto">
        <RichTextEditor
          class="banner-text text-sm text-[#ffffff] pt-2 text-center"
          :blok="{ text: blok.bannerText }"
        />
      </div>
      <div
        v-if="blok.cta && blok.cta[0]"
        class="max-w-[124px] md:max-w-[144px] md:pr-5 py-1 m-auto"
      >
        <CTA
          class="leading-[.15rem]"
          :blok="blok.cta[0]"
          @click="optiBtnEvent"
        />
      </div>
    </div>
    <div class="cursor-pointer" @click="closeBanner = false">
      <span class="material-symbols-outlined text-base text-[#ffffff]">
        close
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BannerBlokProps } from '@/types/Banner';
import { useStoriesStore } from '@/stores/Stories';

const store = useStoriesStore();
await store.fetchData();

const props = defineProps<{
  blok: BannerBlokProps;
  slug?: string;
}>();

const closeBanner = ref(true);
const getBannerDeploySlugs = () => {
  const computedSlugs: string[] = [];
  props.blok.bannerPages.forEach((page) => {
    const story = store.getStoryById(page);
    computedSlugs.push(story?.full_slug!);
  });

  return computedSlugs;
};

const optiBtnEvent = () => {
  window.optimizely = window.optimizely || [];
  window.optimizely.push({
    type: 'event',
    eventName: 'bannerBtnClick',
    tags: {
      revenue: 0, // Optional in cents as integer (500 == $5.00)
      value: 0.0, // Optional as float
    },
  });
};

const bannerDeployPages = getBannerDeploySlugs();

const isBannerPage = computed(() => {
  return bannerDeployPages.includes(props.slug);
});
</script>
<style lang="scss">
.banner-text > p {
  font-size: 0.875rem !important;
}
</style>
